<ng-container [ocpTranslateSection]="data.translateSection">
  <h3 mat-dialog-title class="title">{{ data.title | ocpTranslate }}</h3>
  <mat-dialog-content>{{ data.message | ocpTranslate }}</mat-dialog-content>
  <mat-dialog-actions>
    @if (data.actionButtons?.length) {
      @for (buttonConfig of data.actionButtons; track $index) {
        <ocp-button-universal [config]="buttonConfig"></ocp-button-universal>
      }
    }
  </mat-dialog-actions>
</ng-container>
