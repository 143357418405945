import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  input,
  type OnInit,
  Signal,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDivider } from '@angular/material/divider';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';

import { Store } from '@ngrx/store';

import { OcpTranslatePipe, OcpTranslationSectionDirective } from '@ocp/fusion-cdk/translate';
import { type OcpButtonRealisation, OcpButtonUniversalComponent } from '@ocp/ui-kit/button';
import { OcpProgressSpinnerComponent } from '@ocp/ui-kit/progress-spinner';
import {
  OcpConfirmDialogComponent,
  OcpConfirmDialogData,
  OcpDialogService,
} from '@ocp/ui-kit/dialog';

import { CompanyFooterComponent } from '@libs/core-ui/footer';

import {
  CreateDialogComponent,
  ProjectCardsComponent,
  ProjectPresentationConfig,
  ProjectTableComponent,
} from '../../components';
import { projectActions, projectFeature } from '../../store';
import type { TProject, TProjectCreateRequest } from '../../types';
import { ProjectListConfig, type TProjectListViewMode } from './models';
import { projectListActions, projectListFeature } from './store';

@Component({
  selector: 'lib-project-list',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './project-list.component.html',
  styleUrl: './project-list.component.scss',
  imports: [
    CommonModule,
    MatButtonToggleModule,
    ProjectTableComponent,
    ProjectCardsComponent,
    FormsModule,
    OcpButtonUniversalComponent,
    OcpProgressSpinnerComponent,
    OcpTranslatePipe,
    OcpTranslationSectionDirective,
    CompanyFooterComponent,
    MatDivider,
  ],
})
export class ProjectListComponent implements OnInit {
  configSig = input.required<ProjectListConfig>({ alias: 'config' });

  viewModeSignal: Signal<TProjectListViewMode> = toSignal(
    this._store.select(projectListFeature.selectViewMode),
    {
      requireSync: true,
    },
  );
  projectsSignal: Signal<TProject[]> = toSignal(this._store.select(projectFeature.selectRecords), {
    requireSync: true,
  });
  isLoadingSignal: Signal<boolean> = toSignal(
    this._store.select(projectFeature.selectLoadingInitial),
    {
      requireSync: true,
    },
  );

  presentationConfig = new ProjectPresentationConfig({
    data: computed(() => this.projectsSignal()),
    onItemClick: (project) => this.configSig().onItemClick(project),
    onSettingsClick: (project) => this.configSig().onSettingsClick(project),
    onDeleteClick: (project) => this._deleteProject(project),
  });

  cardsModeButtonConfig: OcpButtonRealisation = {
    actionsConfig: {
      callback: () => this.handleViewModeChange('cards'),
      disabled: signal(false),
      isShowed: signal(true),
    },
    visualisationConfig: {
      appearance: 'icon',
      icon: {
        icon: 'grid_view',
      },
      classNamesDynamic: computed(() => {
        return this.viewModeSignal() === 'cards' ? ['primary'] : [];
      }),
    },
  };
  tableModeButtonConfig: OcpButtonRealisation = {
    actionsConfig: {
      callback: () => this.handleViewModeChange('table'),
      disabled: signal(false),
      isShowed: signal(true),
    },
    visualisationConfig: {
      appearance: 'icon',
      icon: {
        icon: 'lists',
        isCustom: true,
      },
      classNamesDynamic: computed(() => {
        return this.viewModeSignal() === 'table' ? ['primary'] : [];
      }),
    },
  };
  newProjectButtonConfig: OcpButtonRealisation = {
    actionsConfig: {
      callback: () => this._createNewProject(),
    },
    visualisationConfig: {
      appearance: 'flat',
      classNames: ['toolbar-new-btn'],
      icon: {
        icon: 'add',
      },
      label: 'NEW',
    },
  };

  constructor(
    private readonly _store: Store,
    private readonly _ocpDialogService: OcpDialogService,
    private readonly _destroyRef: DestroyRef,
  ) {}

  public ngOnInit(): void {
    this._store.dispatch(projectActions.loadList());
  }

  public deleteProject(project: TProject): void {
    this._store.dispatch(projectActions.deleteProject({ projectId: project.id }));
  }

  public handleViewModeChange(viewMode: TProjectListViewMode): void {
    this._store.dispatch(projectListActions.setViewMode({ viewMode }));
  }

  private _createNewProject(): void {
    const dialogRef = this._ocpDialogService.openDialog<
      CreateDialogComponent,
      TProjectCreateRequest
    >(CreateDialogComponent);

    dialogRef
      .afterClosed()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((result?: TProjectCreateRequest) => {
        if (result == null) {
          return;
        }
        // For Unity project we need to hide viewer3dType select but defaulted to 'occ' during creation
        this._store.dispatch(projectActions.createProject({ payload: result }));
      });
  }

  private _deleteProject(project: TProject): void {
    const dialogRef = this._ocpDialogService.openDialog<
      OcpConfirmDialogComponent,
      boolean,
      OcpConfirmDialogData
    >(OcpConfirmDialogComponent, {
      data: {
        translateSection: 'PROJECT_DELETE_DIALOG',
        title: 'TITLE',
        message: 'MESSAGE',
        actionButtons: [
          {
            actionsConfig: {
              callback: () => {
                dialogRef.close(false);
              },
            },
            visualisationConfig: {
              appearance: 'basic',
              label: 'CANCEL',
            },
          },
          {
            actionsConfig: {
              callback: () => {
                dialogRef.close(true);
              },
            },
            visualisationConfig: {
              appearance: 'flat',
              label: 'CONFIRM',
            },
          },
        ],
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((res?: boolean) => {
        if (res == null || !res) {
          return;
        }
        this.deleteProject(project);
      });
  }
}
