import { ChangeDetectionStrategy, Component, HostBinding, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';

import { OcpTranslatePipe, OcpTranslationSectionDirective } from '@ocp/fusion-cdk/translate';
import { OcpButtonRealisation, OcpButtonUniversalComponent } from '@ocp/ui-kit/button';

export type OcpConfirmDialogData = {
  title: string;
  message: string;
  translateSection?: string;
  actionButtons?: OcpButtonRealisation[];
};

@Component({
  selector: 'ocp-confirm-dialog',
  standalone: true,
  templateUrl: './confirm-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogContent,
    MatDialogActions,
    OcpButtonUniversalComponent,
    OcpTranslatePipe,
    MatDialogTitle,
    OcpTranslationSectionDirective,
  ],
})
export class OcpConfirmDialogComponent {
  @HostBinding('class.ocp-confirm-dialog') hostCssClass = true;

  constructor(
    public dialogRef: MatDialogRef<OcpConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OcpConfirmDialogData,
  ) {}
}
