<ocp-progress-spinner [config]="spinnerConfig" />

<mat-card
  class="card"
  ocpTranslateSection="PROJECT_CARD"
  (click)="handleOpenProjectClick(projectSig())"
>
  <mat-card-title class="card-header">
    <span class="card-title ocp-title-primary">{{ projectSig().name }}</span>
    <ocp-button-universal [config]="openProjectBtnConfig" />
  </mat-card-title>

  <div class="card-preview">
    @let previewUrl = projectSig().previewUrl;

    @if (previewUrl) {
      <img
        [priority]="prioritizeImageLoadingSig()"
        fill="contain"
        [ngSrc]="(previewUrl | ocpSanitizeImage | async) || fallbackImageSrc"
        [alt]="'PREVIEW_ALT' | ocpTranslate"
      />
    } @else {
      <img
        [priority]="prioritizeImageLoadingSig()"
        fill="contain"
        [ngSrc]="fallbackImageSrc"
        [alt]="'PREVIEW_ALT' | ocpTranslate"
      />
    }
  </div>

  <mat-card-content class="card-content" (click)="$event.stopPropagation()">
    @for (control of cardFieldsSig(); track control) {
      <ocp-input class="card-field" [config]="control" />
    }
  </mat-card-content>

  <mat-card-actions class="card-actions">
    <ocp-actions-menu [config]="menuConfig" />
  </mat-card-actions>
</mat-card>
