import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';

import { OcpTranslatePipe, OcpTranslationSectionDirective } from '@ocp/fusion-cdk/translate';
import { type OcpButtonRealisation, OcpButtonUniversalComponent } from '@ocp/ui-kit/button';
import { OcpForm, OcpFormComponent, OcpInput } from '@ocp/ui-kit/form';

import type { TProject } from '../../types';
import type { TCreateDialogForm } from './create-dialog-form.type';

@Component({
  selector: 'lib-create-dialog',
  standalone: true,
  templateUrl: './create-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    OcpFormComponent,
    OcpButtonUniversalComponent,
    OcpTranslationSectionDirective,
    OcpTranslatePipe,
    MatDialogTitle,
    MatDialogActions,
    MatDialogContent,
  ],
})
export class CreateDialogComponent {
  newProjectForm = new OcpForm<TCreateDialogForm>();

  cancelBtnConfig: OcpButtonRealisation = {
    actionsConfig: {
      callback: () => this.dialogRef.close(),
    },
    visualisationConfig: {
      appearance: 'basic',
      label: 'CANCEL',
    },
  };
  submitBtnConfig: OcpButtonRealisation = {
    actionsConfig: {
      callback: () => this._onSubmit(),
    },
    visualisationConfig: {
      appearance: 'flat',
      label: 'SUBMIT',
    },
  };

  constructor(
    public dialogRef: MatDialogRef<CreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TProject,
  ) {
    this.newProjectForm.setControls({
      name: new OcpInput({
        formControl: new FormControl('', Validators.required),
        label: 'PROJECT_NAME',
        objectId: crypto.randomUUID(),
      }),
      description: new OcpInput({
        objectId: crypto.randomUUID(),
        label: 'PROJECT_DESCRIPTION',
      }),
    });
  }

  private _onSubmit(): void {
    const formGroup = this.newProjectForm.getFormGroup();
    if (formGroup.valid) {
      this.dialogRef.close(formGroup.getRawValue());
    }
  }
}
