import type { Signal } from '@angular/core';

import type { OcpRequiredAndPartial } from '@ocp/utils/types';

import type { TProject } from '../../types';

export type TProjectPresentationConfigProps<TData = unknown> = OcpRequiredAndPartial<
  ProjectPresentationConfig<TData>,
  'data' | 'onDeleteClick' | 'onItemClick' | 'onSettingsClick',
  never
>;

export class ProjectPresentationConfig<TData = unknown> {
  data!: Signal<TData>;
  onSettingsClick!: (project: TProject) => void;
  onItemClick!: (project: TProject) => void;
  onDeleteClick!: (project: TProject) => void;

  constructor(props: TProjectPresentationConfigProps<TData>) {
    Object.assign(this, props);
  }
}
