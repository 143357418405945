import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OcpTranslationSectionDirective } from '@ocp/fusion-cdk/translate';

import type { TProject } from '../../types';
import { ProjectPresentationConfig } from '../models';
import type { TProjectCardConfig } from './project-card-config.type';
import { ProjectCardComponent } from './project-card.component';

@Component({
  selector: 'lib-project-cards',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './project-cards.component.html',
  styleUrl: './project-cards.component.scss',
  imports: [CommonModule, ProjectCardComponent, OcpTranslationSectionDirective],
})
export class ProjectCardsComponent {
  configSig = input.required<ProjectPresentationConfig<TProject[]>>({
    alias: 'config',
  });

  cardConfig = computed<TProjectCardConfig>(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
    const { data, ...rest } = this.configSig();
    return rest;
  });
}
