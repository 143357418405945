import type { OcpRequiredAndPartial } from '@ocp/utils/types';

import { TProject } from '../../../types';

type TProjectListConfigProps = OcpRequiredAndPartial<
  ProjectListConfig,
  'onItemClick' | 'onSettingsClick',
  never
>;

export class ProjectListConfig {
  onItemClick!: (item: TProject) => void;
  onSettingsClick!: (item: TProject) => void;

  constructor(props: TProjectListConfigProps) {
    Object.assign(this, props);
  }
}
