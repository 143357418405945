<div class="component-container" ocpTranslateSection="PROJECT_LIST">
  <div class="toolbar-wrapper">
    <div class="toolbar" ocpTranslateSection="TOOLBAR">
      <h3 class="title">{{ 'TITLE' | ocpTranslate }}</h3>
      <ocp-button-universal [config]="newProjectButtonConfig" />

      <div class="toolbar-actions">
        <ocp-button-universal [config]="tableModeButtonConfig" />
        <ocp-button-universal [config]="cardsModeButtonConfig" />
      </div>
    </div>
    <mat-divider />
  </div>

  <div class="content">
    @if (isLoadingSignal()) {
      <div class="position-center">
        <ocp-progress-spinner />
      </div>
    } @else if (projectsSignal().length === 0) {
      <div class="position-center">
        <h4 class="mat-title-large">{{ 'NO_PROJECTS' | ocpTranslate }}</h4>
      </div>
    } @else if (viewModeSignal() === 'table') {
      <lib-project-table [config]="presentationConfig" />
    } @else {
      <lib-project-cards [config]="presentationConfig" />
    }
  </div>

  <lib-company-footer />
</div>
