import { createFeature, createReducer, on } from '@ngrx/store';

import { projectListActions } from './project-list.actions';
import { type TProjectListViewMode } from '../models';

export type TProjectListState = {
  viewMode: TProjectListViewMode;
};

const initialState: TProjectListState = {
  viewMode: 'cards',
};

const projectListReducer = createReducer(
  initialState,
  on(projectListActions.setViewMode, (state, { viewMode }) => ({ ...state, viewMode })),
);

export const projectListFeature = createFeature({
  name: 'projectList',
  reducer: projectListReducer,
});
