import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';

import { OcpTranslatePipe, OcpTranslationSectionDirective } from '@ocp/fusion-cdk/translate';
import { type OcpButtonRealisation, OcpButtonUniversalComponent } from '@ocp/ui-kit/button';
import { OcpSanitizeImagePipe } from '@ocp/utils/pipes';
import { OcpActionMenuConfig, OcpActionsMenuComponent } from '@ocp/ui-kit/menu';
import { OcpInput, OcpInputComponent } from '@ocp/ui-kit/form';
import {
  OcpProgressSpinnerComponent,
  OcpProgressSpinnerConfig,
} from '@ocp/ui-kit/progress-spinner';
import { OcpUtilsDate } from '@ocp/utils-advanced/helpers';

import type { TProject } from '../../types';
import type { TProjectCardConfig } from './project-card-config.type';

@Component({
  selector: 'lib-project-card',
  standalone: true,
  templateUrl: './project-card.component.html',
  styleUrl: './project-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatCardModule,
    MatMenuModule,
    NgOptimizedImage,
    CommonModule,
    OcpTranslationSectionDirective,
    OcpButtonUniversalComponent,
    OcpTranslatePipe,
    OcpSanitizeImagePipe,
    OcpActionsMenuComponent,
    OcpInputComponent,
    OcpProgressSpinnerComponent,
  ],
})
export class ProjectCardComponent {
  projectSig = input.required<TProject>({ alias: 'project' });
  prioritizeImageLoadingSig = input(false, { alias: 'prioritizeImageLoading' });
  configSig = input.required<TProjectCardConfig>({ alias: 'config' });

  fallbackImageSrc = 'assets/images/project-preview-placeholder.png';
  cardFieldsSig = computed<OcpInput[]>(() => this._initializeCardFields(this.projectSig()));
  spinnerConfig = new OcpProgressSpinnerConfig({
    isShowOverlay: true,
    isVisible: computed(() => Boolean(this.projectSig().isDeleting)),
  });

  openProjectBtnConfig: OcpButtonRealisation = {
    actionsConfig: {
      callback: () => this.handleOpenProjectClick(this.projectSig()),
    },
    visualisationConfig: {
      tooltip: 'OPEN_PROJECT_BUTTON_TOOLTIP',
      appearance: 'icon',
      icon: {
        icon: 'arrow_forward',
      },
      classNames: ['open-project-button', 'primary'],
    },
  };

  menuConfig = new OcpActionMenuConfig({
    triggerIcon: { icon: 'more_horiz' },
    menuItems: [
      {
        item: {
          actionsConfig: {
            callback: () => this.configSig().onSettingsClick(this.projectSig()),
          },
          visualisationConfig: {
            appearance: 'menu',
            order: 1,
            icon: {
              icon: 'settings',
            },
            label: 'SETTINGS_BUTTON',
          },
          type: 'button',
        },
      },
      {
        item: {
          actionsConfig: {
            callback: () => this.configSig().onDeleteClick(this.projectSig()),
          },
          visualisationConfig: {
            appearance: 'menu',
            order: 1,
            icon: {
              icon: 'delete',
            },
            label: 'DELETE_BUTTON',
          },
          type: 'button',
        },
      },
    ],
  });

  handleOpenProjectClick(project: TProject): void {
    this.configSig().onItemClick(project);
  }

  private _initializeCardFields(project: TProject): OcpInput[] {
    return [
      new OcpInput({
        label: 'AUTHOR',
        formControl: new FormControl(project.ownerUserName),
        readonly: true,
        objectId: crypto.randomUUID(),
      }),
      new OcpInput({
        label: 'CREATION_DATE',
        formControl: new FormControl(OcpUtilsDate.formatWithKeyword(project.createdAt, 'short')),
        readonly: true,
        objectId: crypto.randomUUID(),
      }),
      new OcpInput({
        label: 'DESCRIPTION',
        formControl: new FormControl(project.description ?? ''),
        readonly: true,
        objectId: crypto.randomUUID(),
      }),
    ];
  }
}
