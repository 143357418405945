import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  type OnInit,
  signal,
} from '@angular/core';

import { OcpTableBasicComponent, type OcpTableBasicConfig } from '@ocp/ui-kit/table';
import { OcpUtilsDate } from '@ocp/utils-advanced/helpers';
import { OcpUtilsString } from '@ocp/utils/helpers';
import { OcpTranslationSectionDirective } from '@ocp/fusion-cdk/translate';

import type { TProject, TProjectSortField } from '../../types';
import { ProjectPresentationConfig } from '../models';
import { type TProjectTableColumnKey } from './project-table-column-key.type';

@Component({
  selector: 'lib-project-table',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './project-table.component.html',
  styleUrl: './project-table.component.scss',
  imports: [OcpTableBasicComponent, OcpTranslationSectionDirective],
})
export class ProjectTableComponent implements OnInit {
  configSig = input.required<ProjectPresentationConfig<TProject[]>>({
    alias: 'config',
  });

  public tableConfig!: OcpTableBasicConfig<TProject, TProjectTableColumnKey, TProjectSortField>;

  public ngOnInit(): void {
    this.tableConfig = {
      data: computed(() => this.configSig().data()),
      translateSection: 'PROJECT_TABLE',
      sortConfig: {
        sort: signal({
          by: 'name',
          order: 'ASC',
        }),
        comparator: {
          name: (a, b, isAsc) => OcpUtilsString.compare(a.name, b.name, isAsc),
          author: (a, b, isAsc) => OcpUtilsString.compare(a.ownerUserName, b.ownerUserName, isAsc),
          description: (a, b, isAsc) => OcpUtilsString.compare(a.description, b.description, isAsc),
          createdAt: (a, b, isAsc) =>
            OcpUtilsDate.compare(new Date(a.createdAt), new Date(b.createdAt), isAsc),
        },
      },
      onRowClick: (project) => this.configSig().onItemClick(project),
      columns: [
        {
          def: 'name',
          header: 'NAME',
          isSortable: true,
          getValue: (project: TProject) => project.name,
        },
        {
          def: 'author',
          header: 'AUTHOR',
          isSortable: true,
          getValue: (project: TProject) => project.ownerUserName,
        },
        {
          def: 'description',
          header: 'DESCRIPTION',
          isSortable: true,
          getValue: (project: TProject) => project.description,
          needTooltip: true,
        },
        // {
        //   def: 'createdAt',
        //   header: 'CREATION_DATE',
        //   isSortable: true,
        //   getValue: (project: TProject) => project.createdAt,
        //   pipe: DatePipe,
        //   pipeArgs: 'medium',
        // },
      ],
      actionColumn: {
        def: 'actions',
        header: 'ACTIONS',
        data: [
          {
            iconData: { icon: 'settings' },
            tooltip: 'SETTINGS_BUTTON_TOOLTIP',
            action: (project) => this.configSig().onSettingsClick(project),
          },
          {
            iconData: { icon: 'delete' },
            tooltip: 'DELETE_BUTTON_TOOLTIP',
            action: (project) => this.configSig().onDeleteClick(project),
          },
        ],
      },
    };
  }
}
