import { createActionGroup, props } from '@ngrx/store';

import { type TProjectListViewMode } from '../models';

export const projectListActions = createActionGroup({
  source: 'Project List',
  events: {
    'Set View Mode': props<{ viewMode: TProjectListViewMode }>(),
  },
});
